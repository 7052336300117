import * as React from "react";
import {Container, Grid, Icon, Image, List, Menu, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import logoSmall from "./images/logo_s_w.png"
import logo from "./images/logo_w.png"

type LayoutProps = {
    children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = (props) => {
    return (
        <div style={{backgroundColor: '#eeeeee', backgroundSize: 'cover'}}>
            <Menu fixed='top' inverted>
                <Container>
                    <Menu.Item header as={Link} to="/">
                        <Image size='mini' src={logoSmall} style={{marginRight: '0.5em'}}/>
                        OSPEAR
                    </Menu.Item>
                    <Menu.Item as={Link} to="/profile"><Icon name='address card'/>Profile</Menu.Item>
                    <Menu.Item as={Link} to="/skill"><Icon name='laptop'/>Skill</Menu.Item>
                    <Menu.Item as={Link} to="/product"><Icon name='block layout'/>Product</Menu.Item>
                    <Menu.Item as={Link} to="/contact"><Icon name='mail outline'/>Contact</Menu.Item>
                </Container>
            </Menu>

            <Container style={{paddingTop: '7em'}}>
                {props.children}
            </Container>

            <Segment inverted vertical style={{margin: '5em 0em 0em', padding: '5em 0em'}}>
                <Container textAlign='center'>
                    <Grid columns={2} stackable verticalAlign='middle' centered divided>
                        <Grid.Column textAlign="right" width={3}>
                            <Image centered size='small' src={logo}/>
                        </Grid.Column>
                        <Grid.Column textAlign="center" width={6}>
                            <Grid.Row>
                                <List horizontal inverted divided link size='small'>
                                    <List.Item as={Link} to='/profile'>
                                        プロフィール
                                    </List.Item>
                                    <List.Item as={Link} to='/skill'>
                                        スキル
                                    </List.Item>
                                    <List.Item as={Link} to='/product'>
                                        実績
                                    </List.Item>
                                </List>
                            </Grid.Row>
                            <Grid.Row style={{marginTop: '0.5em'}}>
                                <List horizontal inverted divided link size='small'>
                                    <List.Item as={Link} to='/contact'>
                                        問い合わせ
                                    </List.Item>
                                    <List.Item as={Link} to='/terms'>
                                        サイトポリシー
                                    </List.Item>
                                    <List.Item as={Link} to='/privacy'>
                                        個人情報保護方針
                                    </List.Item>
                                </List>
                            </Grid.Row>
                            <Grid.Row style={{marginTop: '0.5em'}}>
                                <p>
                                    ©2011 OSPEAR All Rights Reserved.
                                </p>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                </Container>
            </Segment>
        </div>
    );
}
export default Layout
