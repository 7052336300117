import * as React from "react"
import {useEffect} from "react"
import {Container, Header} from "semantic-ui-react";

const TermsPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <Container>
            <Header as={'h2'}>サイトポリシー</Header>
            <p>当Webサイト（www.ospear.jp）は、オースピアにより運営しています。
                ご利用にあたっては、下記のご利用規定をあらかじめご承知いただけますようお願いいたします。</p>
            <h3>免責事項</h3>
            <p>当Webサイト上のコンテンツの内容について妥当性や正確性について保証するものではなく、一切の責任を負い兼ねます。
                当Webサイト上のコンテンツやURLは予告なしに変更または中止されることがあります。当Webサイトは情報提供を目的としており、その他の目的を意図するものではありません。また、理由の如何に関わらず、情報の変更及び当Webサイトの運用の中断または中止によって生じるいかなる損害についても責任を負うものではありません。本規定は利用者の事前承諾を得ることなく変更できるものとします。</p>
            <h3>個人情報保護方針</h3>
            <p>当Webサイトにおける個人情報の取扱いにつきましては「個人情報保護方針」に準じるものとします。</p>
            <h3>著作権</h3>
            <p>当Webサイト内のコンテンツの著作権は、当方または納品先が保有し、許可なく複製、転用など二次利用を禁じます。また、コンテンツの内容を改変することは一切認めておりません。</p>
            <h3>制作したWebサイトの当サイトへの掲載について</h3>
            <p>当方で制作させていただいたWebサイトは、お客様の許可を得てから当サイト内に制作実績として掲載させて頂いています。</p>
            <h3>外部サイトリンクについて</h3>
            <p>当Webサイトは、いくつかの外部サイトへリンクされている場合があります。リンク先Webサイトの内容について、弊社は責任を負うものではありません。</p>
            <h3>当Webサイトへのリンク</h3>
            <p>当Webサイトは自由リンクしていただいて構いません。ただし、当方の信用を損なうようなリンク、アダルトサイト、公的秩序に反するサイトへからリンクは固くお断りします。</p>
        </Container>
    )
}

export default TermsPage
