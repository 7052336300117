import './style.scss'
// import 'semantic-ui-css/semantic.min.css';
import './semantic-css'
import * as React from "react"
import { createRoot } from 'react-dom/client'
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Layout from "./Layout";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import ProductPage from "./pages/ProductPage";
import SkillPage from "./pages/SkillPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";

const App: React.FC = () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<HomePage />}/>
                    <Route path="/profile" element={<ProfilePage />}/>
                    <Route path="/skill" element={<SkillPage />}/>
                    <Route path="/product" element={<ProductPage />}/>
                    <Route path="/contact" element={<ContactPage />}/>
                    <Route path="/terms" element={<TermsPage />}/>
                    <Route path="/privacy" element={<PrivacyPage />}/>
                </Routes>
            </Layout>
        </Router>
    )
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App/>, )
