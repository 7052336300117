import * as React from "react"
import {Container, Grid, Header, List, Rating, Segment, SemanticICONS} from "semantic-ui-react";

const SkillPage: React.FC = () => {
    const skills = [
        {
            category: 'バックエンド',
            languages: [
                {
                    name: 'Ruby',
                    icon: 'code',
                    star: 3,
                    frameworks: [
                        {name: 'Rails', star: 3},
                        {name: 'Sinatra', star: 1}
                    ]
                },
                {
                    name: 'Python',
                    icon: 'python',
                    star: 3,
                    frameworks: [
                        {name: 'FastAPI', star: 3},
                        {name: 'Flask', star: 3},
                        {name: 'Django', star: 2},
                        {name: 'Pandas', star: 2}
                    ]
                },
                {
                    name: 'Go',
                    icon: 'code',
                    star: 3,
                    frameworks: [
                        {name: 'echo', star: 2},
                        {name: 'Gin', star: 2},
                        {name: 'GORM', star: 1}
                    ]
                },
                {
                    name: 'PHP',
                    icon: 'php',
                    star: 3,
                    frameworks: [
                        {name: 'Yii', star: 3},
                        {name: 'Yii2', star: 1},
                        {name: 'Laravel', star: 2},
                        {name: 'Silex', star: 1},
                        {name: 'Smarty', star: 3}
                    ]
                },
                {
                    name: 'Java',
                    icon: 'code',
                    star: 3,
                    frameworks: [
                        {name: 'Groovy', star: 1},
                        {name: 'Clojure', star: 2},
                        {name: 'Struts', star: 2},
                        {name: 'Struts2', star: 2},
                        {name: 'Seasar2', star: 2},
                        {name: 'Slim3', star: 2},
                        {name: 'Spring', star: 1}
                    ]
                },
                {name: 'Perl', icon: 'code', star: 3, frameworks: []},
                {
                    name: 'C#', icon: 'code', star: 2, frameworks: [
                        {name: 'ASP.NET MVC', star: 1}
                    ]
                },
            ]
        },
        {
            category: 'フロントエンド',
            languages: [
                {
                    name: 'Vue.js', icon: 'vuejs', star: 3, frameworks: [
                        {name: 'Vuex', star: 3},
                        {name: 'Nuxt.js', star: 3},
                    ]
                },
                {
                    name: 'React', icon: 'react', star: 2, frameworks: [
                        {name: 'React Router', star: 2},
                        {name: 'React Hooks', star: 2},
                        {name: 'Next.js', star: 1},
                    ]
                },
                {name: 'AngularJS', icon: 'angular', star: 2, frameworks: []},
                {name: 'TypeScript', icon: 'code', star: 2, frameworks: []},
                {name: 'CoffeeScript', icon: 'coffee', star: 2, frameworks: []},
                {name: 'HTML5', icon: 'html5', star: 2, frameworks: []},
                {name: 'CSS3', icon: 'css3', star: 2, frameworks: []},
                {name: 'Percel', icon: 'js', star: 2, frameworks: []},
                {name: 'Webpack', icon: 'js', star: 2, frameworks: []},
                {name: 'Gulp', icon: 'gulp', star: 2, frameworks: []}
            ]
        },
        {
            category: 'データベース',
            languages: [
                {name: 'MySQL', icon: 'database', star: 3, frameworks: []},
                {name: 'PostgreSQL', icon: 'database', star: 1, frameworks: []},
                {name: 'Oracle Database', icon: 'database', star: 1, frameworks: []},
                {name: 'MongoDB', icon: 'database', star: 1, frameworks: []},
                {name: 'Redshift', icon: 'aws', star: 1, frameworks: []},
                {name: 'Firestore', icon: 'database', star: 2, frameworks: []},
                {name: 'Memcached', icon: 'database', star: 3, frameworks: []},
                {name: 'Redis', icon: 'database', star: 3, frameworks: []},
            ]
        },
        {
            category: 'インフラ/クラウド',
            languages: [
                {
                    name: 'AWS', icon: 'aws', star: 3, frameworks: [
                        {name: 'EC2', star: 3},
                        {name: 'ELB', star: 2},
                        {name: 'RDS', star: 3},
                        {name: 'DynamoDB', star: 2},
                        {name: 'S3', star: 3},
                        {name: 'ElasticCache', star: 3},
                        {name: 'SQS', star: 3},
                        {name: 'Redshift', star: 1},
                        {name: 'Lambda', star: 3},
                        {name: 'API Gateway', star: 2},
                        {name: 'Cognito', star: 2},
                    ]
                },
                {
                    name: 'GCP', icon: 'google', star: 3, frameworks: [
                        {name: 'GCE', star: 3},
                        {name: 'GKE', star: 3},
                        {name: 'Cloud Storage', star: 3},
                        {name: 'Cloud SQL', star: 3},
                        {name: 'Cloud Functions', star: 3},
                        {name: 'Pub/Sub', star: 3},
                        {name: 'Firebase authentication', star: 2},
                        {name: 'Firestore', star: 2},
                        {name: 'App Engine', star: 2},
                        {name: 'IAM', star: 3},
                        {name: 'Stackdriver', star: 2},
                        {name: 'Cloud Build', star: 2},
                    ]
                },
                {
                    name: 'Azure', icon: 'microsoft', star: 2, frameworks: [
                        {name: 'ContainerRegistry', star: 2},
                        {name: 'DataFactory', star: 2},
                        {name: 'Batch', star: 2},
                        {name: 'Pipeline', star: 2},
                        {name: 'Key Vault', star: 2},
                    ]
                },
                {name: 'Docker', icon: 'docker', star: 3, frameworks: []},
                {name: 'Ansible', icon: 'server', star: 1, frameworks: []},
                {name: 'Chef', icon: 'server', star: 1, frameworks: []},
                {name: 'CircleCI', icon: 'server', star: 3, frameworks: []},
                {name: 'Travis CI', icon: 'server', star: 1, frameworks: []},
                {name: 'Jenkins', icon: 'jenkins', star: 2, frameworks: []},
            ]
        }
    ]
    return (
        <Container>
            <Header as={'h2'}>スキル</Header>
            <Grid columns={4} stackable divided>
                {skills.map((skill) => {
                    return (
                        <Grid.Column key={skill.category}>
                            <Header as={'h3'}>{skill.category}</Header>
                            {skill.languages.map((lang) => {
                                return <Segment key={lang.name}>
                                    <List divided relaxed>
                                        <List.Item>
                                            <List.Icon name={lang.icon as SemanticICONS}/>
                                            <List.Content>
                                                <List.Header>
                                                    {lang.name}
                                                    <Rating icon='star' defaultRating={lang.star}
                                                            disabled={true}
                                                            maxRating={3}
                                                            style={{marginLeft: '1em'}}/>
                                                </List.Header>
                                                <List.Description>
                                                    {
                                                        lang.frameworks.map(fw => {
                                                            return (
                                                                <div key={fw.name}>
                                                                    {fw.name}
                                                                    <Rating icon='star' size='mini'
                                                                            disabled={true}
                                                                            defaultRating={fw.star}
                                                                            maxRating={3}
                                                                            style={{marginLeft: '1em'}}/>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </List.Description>
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Segment>
                            })}
                        </Grid.Column>
                    )
                })}
            </Grid>
        </Container>
    )
}

export default SkillPage
