import * as React from "react"
import {Container, Header, Item, Segment, Table} from "semantic-ui-react";
import logoSmall from "../images/logo_s.png"

const ProfilePage: React.FC = () => {
    return (
        <Container>
            <Header as={"h2"}>プロフィール</Header>
            <Segment>
                <Item.Group>
                    <Item>
                        <Item.Image size='small' src={logoSmall} verticalAlign='middle'/>

                        <Item.Content>
                      　　   <Item.Header as='a'>オースピア</Item.Header>
                            <Item.Meta>フリーランスのITエンジニア, Ruby, Python, Go言語, PHP, Perl, Java, C#, AWS, GCP</Item.Meta>
                            <Item.Description>
                                <p>Webアプリケーション開発, システム開発を生業にしています</p>
                                <p>要求分析, 要件定義, アーキテクチャ設計, フロントエンド開発, バックエンド開発, インフラ構築など All in One でお仕事を引き受けることが可能です</p>
                                <p>システム開発プロジェクトのチームの一員として協力できます</p>
                            </Item.Description>
                            <Item.Extra>
                                <p>東京都心及び埼玉県所沢市を拠点に活動（企業形態：個人事業）</p>
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Item.Group>
            </Segment>

            <Header as={"h2"}>経歴</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Content</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell>2006-03</Table.Cell>
                        <Table.Cell>金沢大学 工学部 情報システム工学科 卒</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>2006-04</Table.Cell>
                        <Table.Cell>株式会社インターネットイニシアティブ(IIJ) 入社</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>2011-07</Table.Cell>
                        <Table.Cell>株式会社インターネットイニシアティブ(IIJ) 退社</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>2011-07</Table.Cell>
                        <Table.Cell>個人事業 屋号 オースピア 開業</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Container>
    )
}

export default ProfilePage
