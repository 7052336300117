import * as React from "react"
                import {Card, Container, Header} from "semantic-ui-react";

                const ProductPage: React.FC = () => {
                    const items = [
                {
                    header: '広告のレポーティング,運用サポートツール開発',
                    description: 'プロジェクト立ち上げから参画し,要求分析, 要件定義, UI設計, アーキテクチャ設計, インフラ設計を担当した。' +
                    'フロントエンド,バックエンド,ジョブシステムの設計/開発,インフラ構築,CI/CD環境構築と開発全般に関わった',
                    meta: 'Ruby on Rails, Vue.js, Go, MySQL, AWS, RDS, SQS, S3, 他',
                },
                {
                    header: 'マーケティングサービス開発',
                    description: 'リニューアルの新規開発, アーキテクチャ設計からメンバーと一緒に作り上げた。トリガー,アクション,条件分岐を自由に組み立て出来る仕組みを開発した。' +
                    'フロントエンドからバックエンド,ジョブシステムまで設計/開発し, メール配信, LINE配信, SMS配信, スマホアプリへのプッシュ通知を実現',
                    meta: 'Ruby on Rails, Vue.js, Go, MySQL, AWS, RDS, SQS, S3, 他',
                },
                {
                    header: '不動産テックサービス開発',
                    description: 'フロントエンド, バックエンドの開発, パフォーマンスチューニング, リファクタリングなども担当',
                    meta: 'Ruby on Rails, Vue.js, MySQL, Capistrano, Webpack, AWS',
                },
                {
                    header: 'Wordpress CMS連携',
                    description: 'コンテンツ管理システムはWordpressとは別に開発し、Wordpressのプラグインで連携することでメンテナンス性が高い検索サイトを実現',
                    meta: 'PHP5, Laravel, MySQL, Wordpress, Docker',
                },
                {
                    header: 'メール,LINEなどの配信サービス開発',
                    description: 'マーケティングプラットフォームサービスの新機能として配信先を自由に組み立てることができる配信サービスをプロジェクト立ち上げから参画',
                    meta: 'Ruby on Rails, AngularJS, MySQL, LINE Messaging API, AWS, Gulp',
                },
                {
                    header: 'ポイントサービス開発, テスト環境の自動作成ツール',
                    description: '大手ポイントサービスの開発, 検証用のモックAPIサーバ構築, 数十の接続先毎の検証環境をDockerを利用して自動で構築するサービス',
                    meta: 'Java8, Seasar2, React, Docker, Clojure, OracleDB',
                },
                {
                    header: 'クーポンサービス開発',
                    description: 'スマホ向けクーポンサービス, リニューアル2回実施し, アーキテクチャやテーブル設計からやり直した',
                    meta: 'PHP5, Yii, jQuery, MongoDB, MySQL, AWS, Chef, Capistrano',
                },
                {
                    header: 'オンラインアルバムサービス開発',
                    description: 'スマホ向けアルバムサービス',
                    meta: 'PHP5, Yii, jQuery, MySQL',
                },
                {
                    header: 'クーポンサービス開発',
                    description: '飲食店のクーポンサービス, モバイル版も開発',
                    meta: 'PHP5, Silex, jQuery, MySQL, Twitter API',
                },
                {
                    header: '証券サービス開発',
                    description: '証券サービスの機能追加, テストコード作成など',
                    meta: 'C#, SQLServer, Ruby, Python',
                },
                {
                    header: 'ソーシャルゲーム開発',
                    description: '数百万ユーザ, デイリーアクティブユーザ数十万のソーシャルゲーム開発',
                    meta: 'Perl, MySQL',
                },
                {
                    header: 'ログ検索システム開発',
                    description: '組み込みで技術的制約がある中実現',
                    meta: 'PHP4, awk',
                },
                {
                    header: '予約管理システム開発',
                    description: 'システム管理画面、店舗ユーザ管理画面, エンドユーザ画面の3ユーザタイプ毎に開発, サーバサイド、フロントエンドをスクラッチから開発',
                    meta: 'Java, MySQL, jQuery, Backborn.js',
                },
                {
                    header: '写真投稿サイト作成',
                    description: 'PHP, 管理画面、ブログパーツ、デスクトップ常駐Windowsアプリも同時に作成',
                    meta: 'PHP, MySQL',
                },
                {
                    header: 'Wordpressカスタマイズ',
                    description: 'ブログ記事一覧、詳細等の基本ページ構成をカスタマイズ、プラグイン組み込み、オリジナルプラグイン開発',
                    meta: 'WordPress, PHP',
                },
                {
                    header: '掲示板サイト開発',
                    description: 'PHP, スレッドと投稿がある一般的な掲示板システムの構築, chefによるサーバ自動構築を実現',
                    meta: 'PHP5, Yii2, MySQL, Chef',
                },
                {
                    header: 'サンプルプログラム開発',
                    description: 'Javascriptでのドラッグ&ドロップなどのサンプル実装, jQuery版, HTML5版を作成',
                    meta: 'jQuery, HTML5',
                },
                {
                    header: 'Twitter連携Webアプリケーション開発',
                    description: 'TwitterAPIでOAuth認証、Twitter投稿を取得/作成',
                    meta: 'Java, Google App Engine, Twitter API',
                },
                {
                    header: 'スマホアプリのブランディングページ作成',
                    description: 'PCブラウザ、スマホ向けブラウザ向けにレスポンスシブデザイン対応',
                    meta: 'HTML5, CSS3',
                },
                {
                    header: '飲食店Webサイト作成',
                    description: '食事メニューの登録、アルバム投稿、Blog記事自動取得等、CMS機能を搭載',
                    meta: 'Python, Google App Engine',
                },
                    ]
                    return (
                    <Container>
                    <Header as='h2'>実績</Header>
            <Card.Group items={items} />
        </Container>
    )
}

export default ProductPage
