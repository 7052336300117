import * as React from "react"
import {useEffect} from "react"

const PrivacyPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <section>
            <h2>個人情報保護方針</h2>
            <p>オースピアは、お客様から取得した個人情報の重要性を認識し、保護することを事業活動の基本であると共に経営上の最重要な課題の一つと考えています。お客様へ安心・安全・信頼のサービスを提供していくため、以下のような基本方針の徹底を図り、これを遵守しています。</p>
            <h3>基本方針</h3>
            <ol className="decimal">
                <li>当方は、個人情報保護法および関連するその他の法令・規範を遵守し、これを実施し、維持すると共に継続的な改善に努めます。</li>
                <li>当方は、個人情報保護に関する管理体制を確立するとともに、当方規程を従業員に周知し、その遵守徹底に努めます。</li>
                <li>当方は、個人情報をお客様に明示した利用目的の範囲内で取り扱います。また、お客様からご提供頂いた個人情報を、お客様の同意がある場合または正当な理由がある場合を除き、第三者に開示または提供しません。</li>
                <li>当方は、個人情報を正確かつ最新の状態に保つとともに、個人情報への不正アクセス、個人情報の漏えい、滅失、き損等の予防に努め、情報セキュリティの向上、是正を継続的に実施します。</li>
                <li>当方は、お客様からの個人情報に関するお問い合わせ、開示等のご請求に誠実かつ迅速に対応します。</li>
            </ol>
            <h3>個人情報の利用目的</h3>
            <p>当方は、お客様から個人情報をご提供頂く場合、予め個人情報の利用目的を明示し、その利用目的の範囲内で利用します。予め明示した利用目的の範囲を超えて、お客様の個人情報を利用する必要が生じた場合は、お客様にその旨をご連絡し、お客様の同意を頂いた上で利用します。当方が保有する個人情報の利用目的は下記の通りです。</p>
            <ul className="disc">
                <li>ご発注申し込み先、ご発注先ご本人の特定のため</li>
                <li>お問い合わせ・ご相談への対応</li>
                <li>主なサービスであるWebサイト制作のため</li>
                <li>納品物、資料等の発送</li>
                <li>ご発注情報の登録・履歴の記録のため</li>
                <li>サービス、イベント等のご案内送付</li>
                <li>顧客サポート、メンテナンスなどアフターサービスその他で連絡するため</li>
                <li>アンケート調査実施、モニター等の実施</li>
                <li>契約の履行</li>
                <li>金融機関口座の確認やお支払い、ご入金確認などお取引の決済のため</li>
                <li>お客様との商談、お打ち合わせ等</li>
                <li>各種会員制サービスの提供</li>
                <li>個人情報を集計・分析し、個人を識別することができない統計データを作成したり、販売傾向の把握等、市場調査のため</li>
            </ul>
            <h3>個人情報の適正な取得</h3>
            <p>当方では、個人情報の取得は、適法かつ公正な手段で行います。</p>
            <h3>個人情報の提供</h3>
            <p>当方は、次の場合を除き、お客様の個人情報を第三者に開示または提供しません。</p>
            <ol className="decimal">
                <li>お客様の同意がある場合</li>
                <li>法令等に基づき、裁判所、検察庁、警察機関などの公的機関から開示の要請があった場合</li>
                <li>人の生命、身体又は財産の保護のために必要がある場合であって、お客さまの同意を得ることが困難な場合</li>
                <li>利用目的の達成に必要な範囲で、個人情報の取り扱いを委託する場合</li>
                <li>合併、会社分割、営業譲渡その他の事由によって事業の承継が行われる場合</li>
            </ol>
            <p>当方は、上記に関わらず、お客様へのサービス提供、お問い合わせ等への対応に関して、当方の関係会社より対応させて頂くことが適切と判断される場合に、お客様の住所、氏名、電話番号等を当該関係会社等へ提供することがあります。
                この場合、お客様は当方に対し当該関係会社等への個人情報提供の停止を請求することができます。</p>
            <h3>個人情報に関するお問い合わせ</h3>
            <p>お客様の個人情報の開示・訂正・削除等に関するお問い合わせは「CONTACT」からご連絡ください。</p>
            <h3>その他の事項</h3>
            <h4>アクセス情報について</h4>
            <p>当サイトでは、より良いサービスをご提供させて頂くために、アクセスログ情報を取得する場合がございます。予めご了承下さい。アクセスログの取得はお客様の個人情報を特定することを目的としたものではありません。</p>
        </section>
    )
}

export default PrivacyPage
