import * as React from "react"
import {useState} from "react"
import {Container, Form, Header, Icon, Message, SemanticICONS} from "semantic-ui-react";
import axios from 'axios';

interface ContactParams {
    name?: string,
    office?: string,
    email?: string,
    tel?: string,
    content?: string
}

interface Message {
    header: string
    content: string
    icon: string
    show: boolean
    loading: boolean
    success: boolean
    error: boolean
}

const api = axios.create({
    baseURL: '/',
    headers: {
        'Content-Type': 'application/json'
    },
});
const contact = (params: ContactParams) => {
    return api.post('/api/contact', params)
}

const ContactPage: React.FC = () => {
    const [message, setMessage] = useState<Message>({
        header: "",
        content: "",
        icon: "",
        show: false,
        loading: false,
        success: false,
        error: false
    })
    const [params, setParams] = useState<ContactParams>({
        name: "",
        office: "",
        email: "",
        tel: "",
        content: ""
    })
    const [errors, setErrors] = useState<ContactParams>({})

    const handleChange = event => {
        setParams({...params, [event.target.name]: event.target.value})
    };
    const handleSubmit = (event) => {
        event.preventDefault()
        setMessage(prev => ({
            ...prev,
            header: "送信中",
            content: "お待ちください",
            icon: "circle notched",
            success: false,
            error: false,
            show: true,
            loading: true
        }))
        contact(params).then(res => {
            setMessage(prev => ({
                ...prev,
                header: "送信完了",
                content: "お問い合わせありがとうございました",
                icon: "check circle outline",
                success: true,
                loading: false
            }))
        }).catch((err) => {
            setMessage(prev => ({
                ...prev,
                header: "送信失敗",
                content: err && err.response && err.response.data && err.response.data.message || "問い合わせできませんでした。",
                icon: "ban",
                error: true,
                loading: false
            }))
            if (err.response.data.errors) {
                setErrors(prev => ({...prev, ...err.response.data.errors}))
            }
        })
    };
    const handleDismiss = () => !message.loading && setMessage(prev => ({...prev, show: false}))

    return (
        <Container text>
            <Header as={'h2'}>お問い合わせ</Header>
            <p>Webサイト/アプリケーション制作、システム開発、お見積りのご依頼、各種ご相談・ご要望などありましたら、 以下のフォームよりお問い合わせください。</p>
            <p>お客様よりお預かりした個人情報は、お問い合わせ対応のためにのみ利用させていただきます。</p>
            <Form onSubmit={handleSubmit}>
                <Form.Group widths='equal'>
                    <Form.Input name={"name"} value={params.name} fluid label='担当者名' required
                                error={errors.name ? {content: errors.name} : null} onChange={handleChange}/>
                    <Form.Input name={"office"} value={params.office} fluid label='会社名/店舗名'
                                error={errors.office ? {content: errors.office} : null} onChange={handleChange}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input name={"email"} value={params.email} fluid label='メールアドレス' required
                                error={errors.email ? {content: errors.email} : null}
                                onChange={handleChange}/>
                    <Form.Input name={"tel"} value={params.tel} fluid label='電話番号'
                                error={errors.tel ? {content: errors.tel} : null} onChange={handleChange}/>
                </Form.Group>
                <Form.TextArea name={"content"} value={params.content} label='問い合わせ内容' rows={10} required
                               error={errors.content ? {content: errors.content} : null} onChange={handleChange}/>
                <Form.Button primary disabled={message.loading}>送信</Form.Button>
            </Form>
            {message.show ? (
                <Message success={message.success} error={message.error} icon onDismiss={handleDismiss}>
                    <Icon name={message.icon as SemanticICONS} loading={message.loading}/>
                    <Message.Content>
                        <Message.Header>{message.header}</Message.Header>
                        {message.content}
                    </Message.Content>
                </Message>
            ) : null}
        </Container>
    );
}

export default ContactPage
