import * as React from "react"
import {Container, Grid, Image} from "semantic-ui-react";
import {Link} from "react-router-dom"
import logo from "../images/logo.png"

const HomePage: React.FC = () => {
    return (
        <Container text>
            <Image size={'huge'} src={logo} centered/>
            <Grid columns='four' centered divided>
                <Grid.Row>
                    <Grid.Column textAlign="center">
                        <Link to="/profile">Profile</Link>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Link to="/skill">Skill</Link>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Link to="/product">Product</Link>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Link to="/contact">Contact</Link>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default HomePage
